import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import { Helmet } from "react-helmet"
const imageSmokedBacon = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/wright-brand-hickory-smoked-bacon-on-counter-1920x630.png';
const SecondPage = () => (
  <Layout
    metaTitle="How to Make Bacon in the Oven"
    metaDescription="Learn how to bake bacon in the oven from Wright® Brand Bacon. With step-by-step instructions we'll show you how to cook bacon the Wright way."
    metaKeywords=""
  >
    <meta property="og:image" content={imageSmokedBacon} />
        <link rel="image_src" href={imageSmokedBacon} />
    <div id="how-to-details-wrapper">
      <div class="image">
        <img
          src={imageSmokedBacon}
          alt="Wright Brand Hickory Smoked Bacon On Counter"
          class="img-responsive"
        />
      </div>

      <div class="inner-wrapper">
        <div id="how-to-details">
          <div class="container">
            <div class="content-wrapper">
              <h1>Cooking Bacon the Wright Way®</h1>

              <div class="how-to-content">
                <br />
                Ever since the Wright family started making hand-trimmed,
                real-wood smoked bacon in&nbsp;Vernon, Texas back in 1922, we've_
                been pretty keen on tradition. And we're here to let you in on
                our century-old family secret for better bacon. Put away the
                skillet and fire up the oven. That's right. Baking bacon in the
                oven unlocks better flavor and texture, not to mention it's a
                lot easier to clean up. But don't take our word for it.&nbsp;
                <br />
                <br />
                <br />
                <span>
                  <strong>Here's How to Bake Bacon</strong>
                  <br />
                </span>
                1.&nbsp;
                <span>
                  Preheat oven to 350° F.
                  <br />
                </span>
                2.&nbsp;
                <span>
                  Put Wright® Brand Bacon strips directly on a cookie sheet, a
                  few inches apart.
                  <br />
                </span>
                3.&nbsp;
                <span>
                  Bake for 15-­20 minutes. When they’re golden brown, they’re
                  good to go.
                  <br />
                </span>
                4.&nbsp;
                <span>
                  Let the strips dry on a paper towel, drawing off the excess
                  drippings.
                  <br />
                </span>
                5.&nbsp;&nbsp;<span>And that’s it. Bacon the Wright Way</span>
                <span>®.</span>
                <span>
                  <br />
                  <br />
                </span>
                <br />
                <span className="faq">
                  Bacon FAQ
                  <br />
                </span>
                <br />
                <font color="#1b1b1b">
                  What Temperature should I use to cook bacon in the oven?
                  <br />
                </font>
                <i>
                  All ovens are a little different, but we recommend 350
                  Fahrenheit.&nbsp;
                  <br />
                </i>
                <br />
                How long do I bake Bacon?
                <br />
                <i>
                  Making bacon is equal parts art and science. The exact time is
                  up to you- do you prefer your bacon chewy, charred, or
                  somewhere in between? Your best bet is to start checking after
                  12 minutes and keep baking&nbsp;up to 20 minutes until the
                  texture is to your liking.&nbsp;
                  <br />
                </i>
                <br />
                Should I cook bacon on parchment paper?
                <br />
                <i>
                  No need. Save a step and place your strips directly on a
                  baking sheet.&nbsp;
                  <br />
                  <br />
                </i>
                Can you cook bacon in the microwave?&nbsp;
                <br />
                <i>
                  Technically, yes. But we wouldn't recommend it. Around here we
                  don't believe in taking shortcuts. Those extra few minutes
                  will be worth it for bacon perfection.
                </i>
              </div>
            </div>
            <div class="video-container">
              <div class="col-md-6"></div>
              <div class="video-copy col-md-6">
                <div class="video-title"></div>
                <div class="video-description">
                  <p>&nbsp;</p>
                </div>
              </div>


            </div>
          </div>

          <div class="instructions-wrapper how-to-section">
            <div class="container"></div>
          </div>
        </div>
      </div>
    </div>
    <article class="locator-callout">
      <div class="quick-form-container">
        <div class="product-locator-search">
          <h2>Find Our Products</h2>
          <form data-url="/locator/" action="/locator/" method="get">
            <input
              Value="ANY_ANY"
              id="PROD"
              name="PROD"
              type="hidden"
              value="ANY_ANY"
            />
            <div class="input-wrapper">
              <input
                data-val="true"
                onChange
                data-val-regex="Please enter a 5-digit ZIP code"
                data-val-regex-pattern="^\d{5}"
                data-val-required="Please enter a 5-digit ZIP code"
                id="ZIP"
                maxLength="5"
                name="ZIP"
                placeholder="Enter Zip"
                type="tel"
                defaultValue=""
              />
              <input type="submit" value="Locate Bacon"></input>
              <span
                class="field-validation-valid"
                data-valmsg-for="ZIP"
                data-valmsg-replace="true"
              ></span>
            </div>
          </form>
        </div>

      </div>
    </article>
  </Layout>
)

export default SecondPage
